/**
 * 职位分类相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    save: params => {  return API.POST('/sysjobcategory/save', params)},
    list: params => {  return API.POST('/sysjobcategory/list', params)},
    get: params => {  return API.POST('/sysjobcategory/get', params)},
    delete: params => { return API.POST('/sysjobcategory/delete', params) },
    enable: params => { return API.POST('/sysjobcategory/enable', params) },
    disable: params => { return API.POST('/sysjobcategory/disable', params) },
    listByParentId: params => { return API.POST('/category/selectByParentId', params) },
    listRoot: params => {  return API.POST('/category/selectRoot', params)},
}
