/**
 * Created by xiongxiaomeng.
 * 客户相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    add: params => {  return API.POST('/sysjob/add', params)},
    update: params => {  return API.POST('/sysjob/update', params)},
    list: params => {  return API.POST('/sysjob/list', params)},
    get: params => {  return API.POST('/sysjob/get', params)},
    delete: params => { return API.POST('/sysjob/delete', params) },
    save: params => { return API.POST('/sysjob/save', params) },
    updateStatus: params => { return API.POST('/sysjob/updateStatus', params) },
    publish: params => { return API.POST('/sysjob/publish', params) },
    exportBlob: params => { return API.POST('/sysjob/export', params)},
}
