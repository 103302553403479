const backgrounds = [{
        value: 0,
        label: "学历不限"
    }, {
        value: 1,
        label: "初中及以下"
    }, {
        value: 2,
        label: "中专/中技"
    },
    {
        value: 3,
        label: "高中"
    }, {
        value: 4,
        label: "大专"
    }, {
        value: 5,
        label: "本科"
    }, {
        value: 6,
        label: "硕士"
    }, {
        value: 7,
        label: "博士"
    }
];
export default backgrounds;