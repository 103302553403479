<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 5px; text-align: center">
      <div v-if="!!jobId">
        <div slot="header" class="clearfix" style="font-weight: bold">
          <span>{{ employerUser.name }}</span>
        </div>
        <div class="text item" style="margin-top: 5px" v-if="!!employerId">
          <span>{{ employer.companyname }}</span>
        </div>
        <el-row>
          <el-col :span="12">成立时间：{{ employer.buildtime }}</el-col>
          <el-col :span="12">办公地址：{{ employer.street }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="12">联系电话：{{ employer.phone }}</el-col>
          <el-col :span="12">邮箱：{{ employer.email }}</el-col>
        </el-row>
      </div>
      <div v-if="!jobId">
        <el-row>
          <el-col :span="4">请选择雇主企业</el-col>
          <el-col :span="20">
            <el-select
              v-model="employerId"
              placeholder="请选择雇主企业"
              style="float: left;width:500px;"
            >
              <el-option
                v-for="item in employerList"
                :key="item.id"
                :label="item.companyname?item.companyname:item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card" style="margin-bottom: 5px; text-align: center">
      <el-form :model="job" ref="form" :rules="editRules" class="demo-ruleForm">
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="职位分类"
              label-width="100px"
              prop="categoryid"
            >
              <el-select
                style="float: left"
                v-model="job.categoryid"
                placeholder="请选择职位分类"
                @change="handle_changecategory"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
        >
          <el-col :span="24">
            <el-form-item
              label="下级分类"
              label-width="100px"
              prop="subcategoryid"
              @change="handle_changesubcategory"
            >
              <el-select
                style="float: left"
                @change="handle_changesubcategory"
                v-model="job.subcategoryid"
                placeholder="请选择职位下级分类"
              >
                <el-option
                  v-for="item in subcategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="jobId && !job.categoryid && job.category">
          <el-col :span="24">
            <el-form-item label="职位分类" label-width="100px" prop="category">
              <el-input v-model="job.category"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="jobId && !job.subcategoryid && job.subcategory">
          <el-col :span="24">
            <el-form-item
              label="下级分类"
              label-width="100px"
              prop="subcategory"
            >
              <el-input v-model="job.subcategory"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="类型"
              label-width="100px"
              prop="typeid"
              style="float: left"
            >
              <el-select v-model="job.typeid" placeholder="请选择职位类型">
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="职位名称" label-width="100px" prop="title">
              <el-input v-model="job.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="地址" label-width="100px" prop="street">
              <el-input v-model="job.street"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="需求人数" label-width="100px" prop="vacancy">
              <el-input v-model="job.vacancy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="性别需求"
              label-width="100px"
              prop="genderrequire"
            >
              <el-input v-model="job.genderrequire"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="年龄" label-width="100px" prop="agerequire">
              <el-input v-model="job.agerequire"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" v-if="!!job.reward"
            >导入工资{{ job.reward }}，最低工资0为面谈</el-col
          >
          <el-col :span="11">
            <el-form-item
              label="最低月工资"
              label-width="100px"
              prop="minsalary"
            >
              <el-input v-model="job.minsalary"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">K</el-col>
          <el-col :span="11">
            <el-form-item
              label="最高月工资"
              label-width="100px"
              prop="maxsalary"
            >
              <el-input v-model="job.maxsalary"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">K</el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="学历" label-width="100px" prop="education">
              <el-input v-model="job.education"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="专业" label-width="100px" prop="major">
              <el-input v-model="job.major"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="工作年限"
              label-width="100px"
              prop="experience"
            >
              <el-input v-model="job.experience"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="外语水平"
              label-width="100px"
              prop="foreignlanguage"
            >
              <el-input v-model="job.foreignlanguage"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="其它补充要求"
              label-width="100px"
              prop="suplementaryrequire"
            >
              <el-input v-model="job.suplementaryrequire"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="优先条件"
              label-width="100px"
              prop="preferqualification"
            >
              <el-input v-model="job.preferqualification"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="职位描述"
              label-width="100px"
              prop="description"
            >
              <el-input
                type="textarea"
                :rows="10"
                v-model="job.description"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="任职要求"
              label-width="100px"
              prop="requirement"
            >
              <el-input
                type="textarea"
                :rows="10"
                v-model="job.requirement"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handle_validate('form')"
          >确 定</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import JOB from "../../api/lianying/api_job.js";
import USER from "../../api/lianying/api_user.js";
import EMPLOYER from "../../api/lianying/api_employer.js";
import CATEGORY from "../../api/lianying/api_jobcategory.js";
import typeList from "../../common/jobtype.js";
import backgrounds from "../../common/backgrounds.js";

export default {
  data() {
    return {
      job: {},
      employerId: undefined,
      jobId: undefined,

      typeList: typeList,

      employerList: [],

      categoryList: [],
      subcategoryList: [],

      employer: {},
      employerUser: {},

      backgrounds: backgrounds,

      editRules: {
        title: [
          { required: true, message: "请输入职位名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        street: [
          { required: true, message: "请输入职位所在的地址", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        vacancy: [
          { required: true, message: "请输入职位需求人数", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        minsalary: [
          { required: true, message: "请输入职位最低工资", trigger: "blur" },
        ],

        description: [
          { required: true, message: "请输入职位描述", trigger: "blur" },
          {
            min: 1,
            max: 500,
            message: "长度在 1 到 500 个字符",
            trigger: "blur",
          },
        ],
        category: [
          { required: true, message: "请输入职位分类", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        subcategory: [
          { required: true, message: "请输入职位下级分类", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },

  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      // 客户信息

      this.jobId = this.$route.query.id;
      console.log("jobId " + this.jobId);
      if (!!this.jobId) {
        JOB.get({
          id: this.jobId,
        }).then((res) => {
          this.showLoading = false;
          console.log("job res " + JSON.stringify(res));
          if (res.status == 1) {
            this.job = res.data;
            this.employer = this.job.employer;
            this.employerUser = this.job.employerUser;
            this.employerId = this.job.employerid;

            if (
              this.job.province &&
              this.job.city &&
              this.job.area &&
              !this.job.street
            ) {
              this.job.street =
                this.job.province + " " + this.job.city + " " + this.job.area;
            }
            this.initCategory();
          } else {
            this.$message.error(res.msg);
            //this.initAddData();
          }
        });
      } else {
        //employerList
        let params = {};
        params.pageSize = 100000;
        EMPLOYER.list(params).then((res) => {
          console.log("employer list res " + JSON.stringify(res));
          if (res.status == 1) {
            this.employerList = res.data.data;
          }
        });
        
        let tempEmployerId =this.$route.query.employerId;
        if(!!tempEmployerId){
          this.employerId=Number(tempEmployerId);
        }
        this.initEmployer();
        this.initCategory();
      }
    },

    initCategory() {
      CATEGORY.listRoot().then((res) => {
        if (res.status == 1) {
          this.categoryList = res.data;
        }
      });
      if (this.jobId && this.job.categoryid) {
        let params = {};
        params.parentid = this.job.categoryid;
        CATEGORY.listByParentId(params).then((res) => {
          console.log("subcategory list " + JSON.stringify(res));
          if (res.status == 1) {
            this.subcategoryList = res.data;
          }
        });
      }
    },

    initEmployer() {
      console.log("employerId " + this.employerId);
      if (!!this.employerId) {
        let params = {};
        params.id = this.employerId;
        EMPLOYER.get(params).then((res) => {
          console.log("employer res " + JSON.stringify(res));
          if (res.status == 1) {
            this.employer = res.data;
            this.employerUser = this.employer.employerUser;
          }
        });
      }
    },

    handle_validate(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save();
        } else {
          console.log("validate false");
          return false;
        }
      });
    },
    async handle_save() {
      console.log("handle save");
      if (!this.employerId) {
        this.$message.error("请选择企业");
        return;
      }
      if (!this.job.categoryid && !this.job.category) {
        this.$message.error("请选择分类");
        return;
      }
      if (!this.job.subcategoryid && !this.job.subcategory) {
        this.$message.error("请选择下级分类");
        return;
      }
      if (!this.job.employerid && this.employerId) {
        this.job.employerid = this.employerId;
      }

      JOB.save(this.job).then((res) => {
        console.log("save job " + JSON.stringify(res));
        if (1 == res.status) {
          this.$message.success("操作成功！");
          this.job.id = res.data.id;
          this.jobId = this.job.id;
          if (!this.employer) {
            this.initEmployer();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handle_changecategory(e) {
      console.log("change category " + JSON.stringify(e));
      if (e) {
        let selectCategory = this.categoryList.filter((item) => {
          return item.id == e;
        });
        if (selectCategory) {
          this.job.categoryid = e;
          this.job.category = selectCategory[0].name;
        }
        let params = {};
        params.parentid = e;
        CATEGORY.listByParentId(params).then((res) => {
          console.log("subcategory " + JSON.stringify(res));
          if (res.status == 1) {
            this.subcategoryList = res.data;
          }
        });
      }
    },

    handle_changesubcategory(e) {
      if (e) {
        let selectSubcategory = this.subcategoryList.filter((item) => {
          return item.id == e;
        });
        if (selectSubcategory) {
          this.job.subcategoryid = e;
          this.job.subcategory = selectSubcategory[0].name;
        }
      }
      console.log("job " + JSON.stringify(this.job));
    },
  },
};
</script>

<style scoped>
.divauto {
  margin: 10px auto 0 auto;
}
</style>