/**
 * Created by xiongxiaomeng.
 * 客户相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    save: params => {  return API.POST('/sysemployer/save', params)},
    list: params => {  return API.POST('/sysemployer/list', params)},
    get: params => {  return API.POST('/sysemployer/get', params)},
    delete: params => { return API.POST('/sysemployer/delete', params) },
    exportBlob: params => { return API.POST('/sysemployer/export', params) },
    verify: params => { return API.POST('/sysemployer/verify', params) },
    disverify: params => { return API.POST('/sysemployer/disverify', params) },
}
